* {
  box-sizing: border-box;
}

body {
  margin: 0;
  font-family: Arial, Helvetica, sans-serif;
  overflow-y: auto;
  overflow-x: auto;      
}

.header {
  text-align: center;
  padding: 32px;
}

.row {
  display: -ms-flexbox; /* IE 10 */
  display: flex;
  -ms-flex-wrap: wrap; /* IE 10 */
  flex-wrap: wrap;
  padding: 4px 4px 4px 4px;
}

/* Create two equal columns that sits next to each other */
.column {  
  -ms-flex: 33%; /* IE 10 */
  flex: 33%;
  padding: 4px 4px 4px 4px;
}

.column textarea {
  margin-top: 8px;
  vertical-align: middle;
}

textarea {
  resize: none;
    /*display: block;*/
    margin-left: auto;
    margin-right: auto;
}

.content-box-gray .content {
    height:335px;
    max-height: 335px;    
    min-width: 250px;
    overflow: hidden;
    padding: 0 10px 0 10px;
    font-size: 15px;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    border: 1px solid #dae136;
    /*color: #3385FF;*/
    /*transition:  1s ease-in-out;*/
}

.content-box-gray .title {
    height:44px;
    /*width: 80%;*/
    line-height:38px;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    background:#dae136;
    font-size:18px;
    font-weight:bold;
    font-family:verdana;
    display:block;
    color:white;   
    text-shadow: 2px 2px 2px #2e3336; 
    
    /*border: 1px solid gray;*/
    border-bottom:none;
    /*transition:  1s ease-in-out;*/    
  }

.titleText {
  
  float: left;
  padding: 3px 0 0 8px;
}

.gonogo-toggle {
  
  float: right;
  padding: 6px 6px 0 0;
}

.content-box-gray-large .content {    
    width: 1024;
    overflow: hidden;
    padding: 0 10px 0 10px;
    font-size: 15px;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    border: 1px solid #dae136;
    /*color: #3385FF;*/
    /*transition:  1s ease-in-out;*/
}
.content-box-gray-large .title {
    /*height:75px;*/
    line-height:38px;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    background:#dae136;
    font-size:18px;
    font-weight:bold;
    font-family:verdana;
    display:block;
    color:white;
    display:block;
    /*padding:0 0 0 5px;*/
    /*border: 1px solid gray;*/
    border-bottom:none;   
    transition:  1s ease-in-out; 
    text-shadow: 2px 2px 2px #2e3336; 
}
.printContent {
  max-width: 330px;
  padding: 5px 5px 5px 5px;
}

.printContentInner {
  width: 100%;
  font-size: 1vw;
  padding: 5px 0 5px 5px;
}

img
{
Padding: 5px; 
}

body {
  font: 14px "Century Gothic", Futura, sans-serif;
  /*margin: 10px;*/
}

ol, ul {
  padding-left: 30px;
}

.board-row:after {
  clear: both;
  content: "";
  display: table;
}

.status {
  margin-bottom: 10px;
}

.square {
  background: #fff;
  border: 1px solid #999;
  float: left;
  font-size: 24px;
  font-weight: bold;
  line-height: 34px;
  height: 34px;
  margin-right: -1px;
  margin-top: -1px;
  padding: 0;
  text-align: center;
  width: 34px;
}

.square:focus {
  outline: none;
}

.kbd-navigation .square:focus {
  background: #ddd;
}

.game {
  display: flex;
  flex-direction: row;
}

.game-info {
  margin-left: 20px;
}

.metaheader {
  background: lightgray;  
  align-content: flex-start;
  display: -ms-flexbox; /* IE 10 */
  display: flex;
  -ms-flex-wrap: wrap; /* IE 10 */
  flex-wrap: wrap;
  padding: 4px 4px 4px 4px;
}

.metaheaderBlock {
  background: lightgray;  
  align-content: flex-start;
  display: -ms-flexbox; /* IE 10 */
  display: flex;
  -ms-flex-wrap: wrap; /* IE 10 */
  flex-wrap: wrap;
  padding: 4px 4px 4px 4px;
}

.metaheaderBlock label {  
  padding: 1px 4px 1px 4px;
}

.metaheaderLabel {  
  font-size: 15px;
  font-weight: bold;  
}

.metaheaderTitle {  
  font-size: 15px;
  font-weight: bold;  
}

.metaheaderValue {  
  font-style: italic;  
}

.metaheaderInputTitle {  
  min-width: 175px;
  width: auto;
}

.metaheaderInput {  
  min-width: 175px;
  width: auto;   
}

.carrouselItem {  
  width: 100%;
  max-width: 100%;
  height: 100%;  
}

.content-box-gray .content  img{  
  margin: auto;     
  max-height: 150px;
  width: auto;  
  max-width: 330px;
}

.content-box-gray-large .content  img{  
  margin: auto;     
  max-height: 600px;
  width: auto;  
}


.carousel {
  background: yellow;  
  height: 10px; 
}

 /** Button Style **/
 .button {
  /** Font Style **/
   font-family: "HelveticaNeue-Light", "Helvetica Neue Light", "Helvetica Neue", Helvetica, Arial, "Lucida Grande", sans-serif;
  text-transform:uppercase;
  font-size:14px;
	color:rgb(235, 233, 233);
  text-decoration:none;
	font-weight:bold;
	text-transform:uppercase;
	text-shadow:rgba(0, 0, 0, 0.30) 0 -1.25px 0;
  
  /** Button Style **/ 
  display:-moz-inline-stack;
  -webkit-font-smoothing:antialiased;
	display:inline-block;
	vertical-align:middle;
	vertical-align:auto;
	zoom:1;
  margin-top: 10px;
  margin-bottom: 10px;
	border:1px solid #4a4b4d;
	background: #7a7b7c;
	padding:7px 15px;
	-webkit-border-radius:3px;
	-moz-border-radius:3px;
	-ms-border-radius:3px;
	-o-border-radius:3px;
	border-radius:3px;
	-webkit-box-shadow:rgba(255, 255, 255, 0.3) 0 1px 0, inset rgba(255, 255, 255, 0.3) 0 1px 0;
	-moz-box-shadow:rgba(255, 255, 255, 0.3) 0 1px 0, inset rgba(255, 255, 255, 0.3) 0 1px 0;
	box-shadow:rgba(255, 255, 255, 0.3) 0 1px 0, inset rgba(255, 255, 255, 0.3) 0 1px 0;
}

/** Active State Style **/
.button:active {
	border-color:#2e3336;
	-webkit-box-shadow:rgba(255, 255, 255, 0.2) 0 1px 0, inset rgba(0, 0, 0, 0.25) 0 1px 4px;
	-moz-box-shadow:rgba(255, 255, 255, 0.2) 0 1px 0, inset rgba(0, 0, 0, 0.25) 0 1px 4px;
  box-shadow:rgba(255, 255, 255, 0.2) 0 1px 0, inset rgba(0, 0, 0, 0.25) 0 1px 4px;
}

.rec.rec-dot {
  background: white;
  border: 1px #333 solid;  
  outline: none;
  box-shadow: 0 0 1px 1px white;  
}

.rec.rec-dot:hover {
  background: white;
  border: 1px #333 solid;  
  outline: none;
  box-shadow: 0 0 3px 3px #dae136;  
}

.rec.rec-dot_active {
  background: #dae136;  
  box-shadow: 0 0 1px 3px white;
}

.rec.rec-dot_active:hover {
  background: #dae136;  
  border: 1px #333 solid;  
  outline: none;
  box-shadow: 0 0 3px 3px #dae136;  
}

.rec.rec-dot_active:focus {
  background: #dae136;  
  border: 1px #333 solid;  
  outline: none;
  box-shadow: 0 0 3px 3px white;  
}

/*.ck-editor__editable_inline { min-width:100%; min-height:90%; font-size: 11px;  }*/
/*.ck-editor__editable_inline:focus { min-width:100%; min-height:90%; font-size: 44px;  }*/

.rec.rec-slider-container {    
  margin: 0px;
}

/*
.trix-content {
  min-width: 1024px;
  max-height: 800px; 
  overflow-y: auto;
}
*/