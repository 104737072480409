
#content {
  width: 100%;
  background-color: greenyellow;
}
.react-grid-layout {
  /*background: #eee;   */
  background-color: white;
  
}

.columns {
  -moz-columns: 120px;
  -webkit-columns: 120px;
  columns: 120px;
}
.react-grid-item {
  box-sizing: border-box;
}
.react-grid-item:not(.react-grid-placeholder) {
  /*background: #ccc;*/
  background-color: white;
  border: 1px dotted black;
}
.react-grid-item.resizing {
  opacity: 0.9;
}
.react-grid-item.static {
  background: #cce;
}
.react-grid-item .text {
  font-size: 24px;
  text-align: center;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  height: 24px;  
}
.react-grid-item .minMax {
  font-size: 12px;
}
.react-grid-item .add {
  cursor: pointer;
}
.react-grid-dragHandleExample {
  cursor: move; /* fallback if grab cursor is unsupported */
  cursor: grab;
  cursor: -moz-grab;
  cursor: -webkit-grab;
}
li b {
  font-size: 19px;
  line-height: 14px;
}



.react-grid-layout {
  position: relative;
  transition: height 200ms ease;
}
.react-grid-item {
  transition: all 200ms ease;
  transition-property: left, top;
}
.react-grid-item.cssTransforms {
  transition-property: transform;
}
.react-grid-item.resizing {
  z-index: 1;
  will-change: width, height;
}

.react-grid-item.react-draggable-dragging {
  transition: none;
  z-index: 3;
  will-change: transform;
}

.react-grid-item.react-grid-placeholder {
  background: red;
  opacity: 0.2;
  transition-duration: 100ms;
  z-index: 2;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -o-user-select: none;
  user-select: none;
}

.react-grid-item > .react-resizable-handle {
  position: absolute;
  width: 20px;
  height: 20px;
  bottom: 0;
  right: 0;
  background: url("data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBzdGFuZGFsb25lPSJubyI/Pg08IS0tIEdlbmVyYXRvcjogQWRvYmUgRmlyZXdvcmtzIENTNiwgRXhwb3J0IFNWRyBFeHRlbnNpb24gYnkgQWFyb24gQmVhbGwgKGh0dHA6Ly9maXJld29ya3MuYWJlYWxsLmNvbSkgLiBWZXJzaW9uOiAwLjYuMSAgLS0+DTwhRE9DVFlQRSBzdmcgUFVCTElDICItLy9XM0MvL0RURCBTVkcgMS4xLy9FTiIgImh0dHA6Ly93d3cudzMub3JnL0dyYXBoaWNzL1NWRy8xLjEvRFREL3N2ZzExLmR0ZCI+DTxzdmcgaWQ9IlVudGl0bGVkLVBhZ2UlMjAxIiB2aWV3Qm94PSIwIDAgNiA2IiBzdHlsZT0iYmFja2dyb3VuZC1jb2xvcjojZmZmZmZmMDAiIHZlcnNpb249IjEuMSINCXhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHhtbDpzcGFjZT0icHJlc2VydmUiDQl4PSIwcHgiIHk9IjBweCIgd2lkdGg9IjZweCIgaGVpZ2h0PSI2cHgiDT4NCTxnIG9wYWNpdHk9IjAuMzAyIj4NCQk8cGF0aCBkPSJNIDYgNiBMIDAgNiBMIDAgNC4yIEwgNCA0LjIgTCA0LjIgNC4yIEwgNC4yIDAgTCA2IDAgTCA2IDYgTCA2IDYgWiIgZmlsbD0iIzAwMDAwMCIvPg0JPC9nPg08L3N2Zz4=");
  background-position: bottom right;
  padding: 0 3px 3px 0;
  background-repeat: no-repeat;
  background-origin: content-box;
  box-sizing: border-box;
  cursor: se-resize;
}

.ck-editor__editable_inline { 
  height: 100% !important; 
  width: 100%;  
  font-size: clamp(var(--minimum-font-size-screen-p), calc(var(--default-font-size-screen-p) + var(--correction-font-size-screen-p)), var(--maximum-font-size-screen-p));
  /*font-size: var(--myfontsize);*/
  /*font-size: clamp(1rem, -1.1687rem + 9.6386vw, 2rem);*/
  /*font-size: calc(var(--myfontsize) + (24 - 4) * ((100vw - 120px) / (2560 - 120)));*/
  /*font-size: calc(12px + (24 - 12) * ((100vw - 400px) / (800 - 400)));*/
}

h1 {
  font-size: clamp(var(--minimum-font-size-screen-h1), calc(var(--default-font-size-screen-h1) + var(--correction-font-size-screen-h1)), var(--maximum-font-size-screen-h1));    
}

h2 {
  font-size: clamp(var(--minimum-font-size-screen-h2), calc(var(--default-font-size-screen-h2) + var(--correction-font-size-screen-h2)), var(--maximum-font-size-screen-h2));    
}

h3 {
  font-size: clamp(var(--minimum-font-size-screen-h3), calc(var(--default-font-size-screen-h3) + var(--correction-font-size-screen-h3)), var(--maximum-font-size-screen-h3));  
}

h4 {
  font-size: clamp(var(--minimum-font-size-screen-h4), calc(var(--default-font-size-screen-h4) + var(--correction-font-size-screen-h4)), var(--maximum-font-size-screen-h4));  
}

p {
  font-size: clamp(var(--minimum-font-size-screen-p), calc(var(--default-font-size-screen-p) + var(--correction-font-size-screen-p)), var(--maximum-font-size-screen-p));
}

